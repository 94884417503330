export const tabletWidth = '(min-width: 767.98px)';

export enum LOGO {
  desktop = 100,
  tablet = 60,
}

export enum TIME {
  hours = 3600,
  min = 60,
  sec = 60,
}

export const oneSecond = 1000;

export enum ErrorMessage {
  REQUIRED = 'Заполните все необходимые поля',
}

export enum PATH {
  CREDIT_CARD = '/credit_card',
  CREDIT_CASH = '/credit_cash',
  INSTALLMENT_CARD = '/installment_card',
}
