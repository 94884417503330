import React, { FC, useContext, useEffect, Suspense, lazy } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { YMInitializer } from 'react-yandex-metrika';

import Glimmer from '../components/Glimmer/Glimmer';
import { ModalContext, ModalContextType } from '../context/ModalProvider';
import 'react-toastify/dist/ReactToastify.css';
import { useGeo } from '../hook/useGeo';
import { useHistoryWithUTM } from '../hook/useHistoryWithUTM';
import { LandingStore } from '../themes';

import s from './App.module.scss';
import { AuthModal } from './Form/components/AuthModal';

const Home1 = lazy(() => import('./Home/Home1/Home1'));
const Home2 = lazy(() => import('./Home/Home2/Home2'));
const Home3 = lazy(() => import('./Home/Home3/Home3'));
const Home4 = lazy(() => import('./Home/Home4/Home4'));
const Second = lazy(() => import('./Second/Second'));

export const App: FC = () => {
  const { openAuthModal, setOpenAuthModal } = useContext(
    ModalContext,
  ) as ModalContextType;
  const history = useHistoryWithUTM();
  useEffect(() => {
    history.toHttps();
  }, []);
  useGeo();

  return (
    <>
      <YMInitializer
        accounts={[90896721]}
        options={{
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          trackHash: true,
        }}
      />
      <div className={s.wrapper}>
        <main className={s.page} data-cy="main">
          <Suspense fallback={<Glimmer />}>
            <Switch>
              <Route
                exact
                path="/1"
                render={() => (
                  <Home1
                    banner={LandingStore.landing1.banner}
                    loan={LandingStore.landing1.loan}
                    footer={LandingStore.landing1.footer}
                  />
                )}
              />
              <Route
                exact
                path="/2"
                render={() => (
                  <Home2
                    banner={LandingStore.landing2.banner}
                    loan={LandingStore.landing2.loan}
                    footer={LandingStore.landing2.footer}
                  />
                )}
              />
              <Route
                exact
                path="/3"
                render={() => (
                  <Home3
                    banner={LandingStore.landing3.banner}
                    loan={LandingStore.landing3.loan}
                    footer={LandingStore.landing3.footer}
                  />
                )}
              />
              <Route
                path="/4"
                render={() => (
                  <Home4
                    banner={LandingStore.landing4.banner}
                    loan={LandingStore.landing4.loan}
                    footer={LandingStore.landing4.footer}
                  />
                )}
              />
              <Route exact path="/second" render={() => <Second />} />
              <Route
                exact
                path="/"
                render={() => (
                  <Home1
                    banner={LandingStore.landing1.banner}
                    loan={LandingStore.landing1.loan}
                    footer={LandingStore.landing1.footer}
                  />
                )}
              />

              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </Suspense>
          {openAuthModal && (
            <AuthModal
              openAuthModal={openAuthModal}
              setOpenAuthModal={setOpenAuthModal}
            />
          )}
        </main>
      </div>
      <ToastContainer />
    </>
  );
};
