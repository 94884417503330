import { RefObject } from 'react';

import { toast } from 'react-toastify';

import com from '../styles/common.module.scss';
import { GeoObjectType } from '../types';

const DEFAULT_SLEEP = 0;
const DELAY_SLEEP_FOR_CHECK_ERROR = 100;
const PAD_START_LENGTH = 2;
const SECONDS_COUNT = 60;
const INITIAL_INDEX = 0;

export const sleep = (ms: number = DEFAULT_SLEEP) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const resetMask = (value: string) => value.replace(/\D/g, '');

export const checkFocusError = async (
  focusRef: RefObject<HTMLFormElement>,
  setFocus: (value: boolean) => void,
  focus: boolean,
) => {
  await sleep(DELAY_SLEEP_FOR_CHECK_ERROR);
  if (focusRef.current && focus) {
    const input: NodeListOf<HTMLInputElement> =
      focusRef.current.querySelectorAll('input');
    for (let i = INITIAL_INDEX; i < input.length; i++) {
      const el = input[i];
      if (el.hasAttribute('data-error') || el.hasAttribute('aria-errormessage')) {
        el.focus();
        el.scrollIntoView({ block: 'center', behavior: 'smooth' });
        setFocus(false);
        return;
      }
    }
  }
};

export const setTextTimer = (timer: number) => {
  const minutes = Math.floor(timer / SECONDS_COUNT);
  const seconds = Math.floor(timer % SECONDS_COUNT);
  const stringMin = String(minutes).padStart(PAD_START_LENGTH, '0');
  const stringSec = String(seconds).padStart(PAD_START_LENGTH, '0');

  return `${stringMin} : ${stringSec}`;
};

export const notify = (text: string) =>
  toast.error(text, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: com.notify,
  });

export const smartVetrinaRedirect = (replace: boolean) => {
  const url = `https://cento-finance.ru/kk${window.location.search}`;
  if (replace) {
    window.location.replace(url);
  } else {
    window.location.assign(url);
  }
};

export const animate = ({
  timing,
  draw,
  duration,
}: {
  timing: (value: number) => number;
  draw: (value: number) => void;
  duration: number;
}) => {
  const start = performance.now();

  requestAnimationFrame(function anim(timeDraw) {
    let timeFraction = (timeDraw - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    const progress = timing(timeFraction);

    draw(progress);

    if (timeFraction < 1) {
      requestAnimationFrame(anim);
    }
  });
};

export const redirect = () => {
  window.open(
    `${window.location.origin}/credit_parameter_info${window.location.search}`,
    '_blank',
  );
  window.location.replace(`https://dengionline.site/${window.location.search}`);
};

export const addSpaceForDigit = (digit: number): string =>
  Number(digit).toLocaleString('ru-RU');

export const checkDigit = (digit: number): string => {
  if (digit === 1000000) return '1 млн ₽';
  return digit.toString();
};

export const isGeoInfoFilled = (geoInfo: GeoObjectType) =>
  !(Object.values(geoInfo).includes('') || Object.keys(geoInfo).length === 0);

export const getCurrentYear = () => {
  const date = new Date(Date.now());
  return date.getFullYear();
};
