import axios from 'axios';

import { getServerUrl } from './utils/utilsApi';

// export const SERVER_URL = 'http://192.168.0.60:8000/'
export const SERVER_URL = getServerUrl();
export const PRODUCTION_URL = true;

export const instance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    frm: window.location.search || null,
    fronturl: window.location.origin,
  },
  baseURL: SERVER_URL,
});
