import React from 'react';

import s from './style/Button.module.scss';

import { ButtonPropsType } from 'shared/Button/type';

export const Button: React.FC<ButtonPropsType> = React.memo(
  ({ className, children, containerClassName, down, dataCy, ...restProps }) => {
    const finalClassName = ` ${s.button} ${down ? s.down : ''} ${className || ''}`;

    return (
      <div className={containerClassName}>
        <button type="button" className={finalClassName} {...restProps} data-cy={dataCy}>
          <span>{children}</span>
        </button>
      </div>
    );
  },
);
