import React, { ChangeEvent, FocusEvent } from 'react';

import InputMask from 'react-input-mask';

import s from './style/InputField.module.scss';

import { InputFieldPropsType } from 'shared/InputField/type';

const RESULT_MAX_LENGTH = 4;
const RESULT_MIN_LENGTH = 0;
/* eslint-disable */

export const InputField: React.FC<InputFieldPropsType> = React.memo(
  ({
    error,
    label,
    required,
    inputClass,
    labelClass,
    touched,
    value,
    type = 'text',
    mask,
    ref,
    id,
    onChange,
    setField,
    name,
    onBlur,
    ...restProps
  }) => {
    const checkField = () => touched && !error && !!value;
    const inputClassName = `${s.input} ${inputClass || ''} ${
      error ? s.input__error : ''
    } ${checkField() ? s.input__confirm : ''}`;
    const labelClassName = `${s.label} ${labelClass || ''}`;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e);
      if (setField) {
        const result = e.currentTarget.value.replace(/[^\d ]/gi, '');
        setField(name, result);
      }
    };
    const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) onBlur(e);
      if (setField && typeof value === 'string') {
        if (value?.length < RESULT_MAX_LENGTH && value?.length > RESULT_MIN_LENGTH) {
          setField(name, `${value}000`);
        }
      }
    };

    return (
      <div className={`${s.inputField}`}>
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required ? <span> *</span> : ''}
        </label>
        <div className={s.inputField__container}>
          {!mask ? (
            <input
              id={id}
              ref={ref}
              autoComplete="{something}"
              className={inputClassName}
              value={value}
              type={type}
              name={name}
              data-error={error}
              onChange={handleChange}
              onBlur={onBlurHandler}
              data-cy={`input_field_${name}`}
              {...restProps}
            />
          ) : (
            // @ts-ignore
            <InputMask
              id={id}
              role="presentation"
              autoComplete="{something}"
              mask={mask}
              value={value}
              className={inputClassName}
              type={type}
              name={name}
              onChange={handleChange}
              onBlur={onBlurHandler}
              data-error={error}
              data-cy={`input_field_${name}`}
              {...restProps}
            />
          )}
          <div className={s.inputField__icon}>
            {checkField() && (
              <span className={s.iconComplete} data-cy={`input-success_text_${name}`} />
            )}
            {error && <span className={s.iconError} />}
          </div>
        </div>
        <div className={s.inputField__error} data-cy={`input-error_text_${name}`}>
          {error && <span className={s.error}>{error}</span>}
        </div>
      </div>
    );
  },
);
