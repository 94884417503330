export const getServerUrl = () => {
  const currentFrontendDomain = window.location.origin;
  const developerBackend = 'https://back.card-online.online';
  const productionBackend = 'https://back.card-online.online';

  let serverUrl;
  if (
    currentFrontendDomain.includes('dev-front') ||
    currentFrontendDomain.includes('localhost')
  ) {
    serverUrl = developerBackend;
  } else {
    serverUrl = productionBackend;
  }

  return serverUrl;
};
