import { instance } from 'api/api-configs';
import { FormApiProps } from 'api/form-service/type';
import { CreditParameterInfo } from 'store/creditFormSlice/types';

export const FormApi: FormApiProps = {
  async createUser(data) {
    return instance.post<CreditParameterInfo>('api/create_user', data);
  },
  async updateUser(data) {
    return instance.post<CreditParameterInfo>('api/update_user', data);
  },
};
