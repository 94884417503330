import React from 'react';

import s from './style/Modal.module.scss';

import { ModalPropsType } from 'shared/Modal/type';

export const Modal: React.FC<ModalPropsType> = ({
  modalActive,
  setModalActive,
  children,
}) => (
  <div
    role="presentation"
    className={modalActive ? `${s.modal} ${s.modal__active}` : s.modal}
    onClick={() => setModalActive(false)}
    data-cy="modal_window_container"
  >
    <div
      role="presentation"
      className={
        modalActive ? `${s.modal__content} ${s.modal__content_active}` : s.modal__content
      }
      onClick={e => e.stopPropagation()}
      data-cy="modal_window_content"
    >
      {children}
    </div>
  </div>
);
