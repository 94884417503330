import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunkType } from '../store';

import { AppInitialStateType, AppStatusType } from 'store/appSlice/types';
import { GeoObjectType, Nullable } from 'types';

const appInitialState: AppInitialStateType = {
  errorMessage: null,
  appStatus: 'success',
  auth: false,
  geo: {
    ip: '',
    city: '',
    region: '',
    countryCode: '',
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<AppStatusType>) => {
      state.appStatus = action.payload;
    },
    setAppError: (state, action: PayloadAction<Nullable<string>>) => {
      state.errorMessage = action.payload;
    },
    setGeo: (state, action: PayloadAction<GeoObjectType>) => {
      state.geo = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const { setAppStatus, setAppError, setGeo } = appSlice.actions;

export const handleAppError =
  (err: Nullable<string>): AppThunkType =>
  (dispatch, getState) => {
    const { errorMessage, appStatus } = getState().app;
    if (!errorMessage && appStatus !== 'error') {
      dispatch(setAppStatus('error'));
      dispatch(setAppError(err || 'Some error occurred'));
    }
  };
