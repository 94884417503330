import * as Yup from 'yup';

import { resetMask } from '../../../../../utils';

import { NameRegularExpression } from 'utils/regex';

const nameMessage = 'Укажите ваше имя. Допускаются: кириллица, пробел, дефис, тире';
const surnameMessage =
  'Укажите вашу фамилию. Допускаются: кириллица, пробел, дефис, тире';
const patronymicMessage =
  'Укажите ваше отчество. Допускаются: кириллица, пробел, дефис, тире';

export const CreditInfoSchema = Yup.object().shape({
  phone_number: Yup.string().test('phone_number', (value, context) =>
    resetMask(value as string).length === 11
      ? true
      : context.createError({
          message: `Укажите корректный номер телефона`,
        }),
  ),
  surname: Yup.string().matches(NameRegularExpression, surnameMessage),
  name: Yup.string().matches(NameRegularExpression, nameMessage),
  patronymic: Yup.string().matches(NameRegularExpression, patronymicMessage),
});
