import React, { FC } from 'react';

import { CreditInfoForm } from '../CreditInfo';

import { AuthModalPropsType } from 'pages/Form/components/AuthModal/type';
import { Modal } from 'shared/Modal';

export const AuthModal: FC<AuthModalPropsType> = ({
  openAuthModal,
  setOpenAuthModal,
}) => (
  <Modal modalActive={openAuthModal} setModalActive={setOpenAuthModal}>
    <CreditInfoForm isFormType={false} />
  </Modal>
);
