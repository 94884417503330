import { createContext, FC, useMemo, useState } from 'react';

export type ModalContextType = {
  openAuthModal: boolean;
  setOpenAuthModal: (value: boolean) => void;
  setInitPhone: (value: string) => void;
  initPhone: string;
  initNumber: number;
  setInitNumber: (value: number) => void;
};

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider: FC = ({ children }) => {
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [initPhone, setInitPhone] = useState('');
  const [initNumber, setInitNumber] = useState(1);

  const memoValueProvider = useMemo(
    () => ({
      openAuthModal,
      setOpenAuthModal,
      initPhone,
      setInitPhone,
      initNumber,
      setInitNumber,
    }),
    [openAuthModal, initPhone, setInitPhone, setOpenAuthModal],
  );
  return (
    <ModalContext.Provider value={memoValueProvider}>{children}</ModalContext.Provider>
  );
};
