import React, { FC } from 'react';

import s from './style/Paper.module.scss';

import { PaperPropsType } from 'shared/Paper/type';
import com from 'styles/common.module.scss';

export const Paper: FC<PaperPropsType> = ({ children, container, className }) => (
  <div className={`${container ? com.container : ''}`}>
    <fieldset className={`${s.paper} ${className || ''}`}>{children}</fieldset>
  </div>
);
