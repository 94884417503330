import { useEffect } from 'react';

import axios from 'axios';

import { GeoObjectType } from '../types';

import { useAppDispatch } from './useAppDispatch';

import { setGeo } from 'store/appSlice/appSlice';

export const useGeo = () => {
  const appDispatch = useAppDispatch();
  useEffect(() => {
    axios.get('https://api.db-ip.com/v2/free/self').then(response => {
      const responseData: GeoObjectType = {
        ip: response.data.ipAddress || '',
        region: response.data.stateProv || '',
        city: response.data.city || '',
        countryCode: response.data.countryCode || '',
      };
      appDispatch(setGeo(responseData));
    });
  }, []);
};
