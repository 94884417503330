import banner_desktop_1 from '../assets/banner/home/1_desktop.webp';
import banner_mobile_1 from '../assets/banner/home/1_mobile.webp';
import banner_desktop_2 from '../assets/banner/home/2_desktop.webp';
import banner_mobile_2 from '../assets/banner/home/2_mobile.webp';
import banner_desktop_3_kn from '../assets/banner/home/5_5.webp';
import banner_desktop_3 from '../assets/banner/home/zero.webp';
import desktop_1_kn_mfo from '../assets/banner/landing1/1_banner_kn(mfo)_desktop.webp';
import mobile_1_kn_mfo from '../assets/banner/landing1/1_banner_kn(mfo)_mobile.webp';
import banner_desktop_2_kn_mfo from '../assets/banner/landing2/2_banner_kn(mfo)_desktop.webp';
import banner_mobile_2_kn_mfo from '../assets/banner/landing2/2_banner_kn(mfo)_mobile.webp';
import icon_36_1 from '../assets/icons/advantages/landing1/1_36week.svg';
import icon_60_1 from '../assets/icons/advantages/landing1/1_60month.svg';
import icon_365_1 from '../assets/icons/advantages/landing1/365.svg';
import cashback_1 from '../assets/icons/advantages/landing1/cashback.svg';
import credit_limit_1 from '../assets/icons/advantages/landing1/credit-limit.svg';
import free_1 from '../assets/icons/advantages/landing1/free.svg';
import mobile_1 from '../assets/icons/advantages/landing1/mobile.svg';
import passport from '../assets/icons/advantages/landing1/passport.svg';
import percent from '../assets/icons/advantages/landing1/percent.svg';
import icon_365_2 from '../assets/icons/advantages/landing2/2_365.svg';
import icon_36_2 from '../assets/icons/advantages/landing2/2_36week.svg';
import icon_60_2 from '../assets/icons/advantages/landing2/2_60month.svg';
import cashback_2 from '../assets/icons/advantages/landing2/2_cashback.svg';
import credit_limit_2 from '../assets/icons/advantages/landing2/2_credit-limit.svg';
import free_2 from '../assets/icons/advantages/landing2/2_free.svg';
import gift_2 from '../assets/icons/advantages/landing2/2_gift.svg';
import mobile_2 from '../assets/icons/advantages/landing2/2_mobile.svg';
import passport_2 from '../assets/icons/advantages/landing2/2_passport.svg';
import percent_2 from '../assets/icons/advantages/landing2/2_percent.svg';
import icon_365_3 from '../assets/icons/advantages/landing3/3_365.svg';
import icon_36_3 from '../assets/icons/advantages/landing3/3_36week.svg';
import icon_60_3 from '../assets/icons/advantages/landing3/3_60month.svg';
import cashback_3 from '../assets/icons/advantages/landing3/3_cashback.svg';
import credit_limit_3 from '../assets/icons/advantages/landing3/3_credit-limit.svg';
import free_3 from '../assets/icons/advantages/landing3/3_free.svg';
import gift_3 from '../assets/icons/advantages/landing3/3_gift.svg';
import mobile_3 from '../assets/icons/advantages/landing3/3_mobile.svg';
import passport_3 from '../assets/icons/advantages/landing3/3_passport.svg';
import percent_3 from '../assets/icons/advantages/landing3/3_percent.svg';
import feedback_1_1 from '../assets/icons/feedback/1_1.webp';
import feedback_1_2 from '../assets/icons/feedback/1_2.webp';
import feedback_1_3 from '../assets/icons/feedback/1_3.webp';
import feedback_2_1 from '../assets/icons/feedback/2_1.webp';
import feedback_2_2 from '../assets/icons/feedback/2_2.webp';
import feedback_2_3 from '../assets/icons/feedback/2_3.webp';

import { DomainsName } from './helpers';

export interface LogoUrls {
  imageMobile?: string;
  imageDesktop?: string;
  imageMan?: string;
  imageWoman?: string;
}

export interface FooterFields {
  domain: string;
  owner: OwnerInfoTypeKeys;
}

export interface BannerFields {
  title?: string;
  titleMobile?: string;
  subtitle?: string[];
  subtitleMobile?: string[];
  logo: LogoUrls;
}
export type AdvantagesCardDataType = {
  id: number;
  icon: string;
  text: string;
};
export type FeedbackDataType = {
  icon: string;
  title: string;
  subtitle: string;
  text: string;
  author?: string;
};
const feedbackText = {
  first: {
    text_1:
      'Оформлял кредитную карту,\nодобрили быстро и бесплатно\nпривезли в назначенное\nвремя.Советую всем друзьям и\nродственникам.',
    text_2:
      'Давно пользуюсь кредитками.\nДоволен поддержкой и\nвозможностями. Это самая\nудобная кредитка, из тех которые у\nменя были.',
    text_3:
      'Я кредиткой уже несколько лет\nпользуюсь. Стараюсь за льготный\nпериод не выходить, проценты\nпочти не плачу. По мне, кредитка\nлучше, чем обычный кредит.',
  },
  second: {
    text_1:
      'Оформлял кредит, одобрили\nбыстро и деньги получил в\nназначенное время.Советую всем\nдрузьям и родственникам.',
    text_2:
      'Давно пользуюсь кредитами.\nДоволен поддержкой и\nвозможностями. Это самый\nудобный сервис, из тех которыми я\nпользовался.',
    text_3:
      'Недавно получала кредит. Очень\nдовольна обслуживанием! Хочу\nвыразить благодарность\nсотрудникам, делают всё очень\nграмотно и быстро.',
  },
  third: {
    text_1:
      'Оформлял заём, одобрили быстро\nи деньги получил в назначенное\nвремя.Советую всем друзьям и\nродственникам.',
    text_2:
      'Давно пользуюсь услугами МФО.\nДоволен поддержкой и\nвозможностями. Это самый\nудобный сервис, из тех которыми я\nпользовался.',
    text_3:
      'Недавно получала заём. Очень\nдовольна обслуживанием! Хочу\nвыразить благодарность\nсотрудникам, делают всё очень\nграмотно и быстро.',
  },
};
export type HowItWorksDataType = {
  title: string;
  subtitle: string;
  text?: string[];
  titleMobile?: string;
  subtitleMobile?: string;
};
export type AtUsDataTabType = {
  id: number;
  title: string;
  subtitle: string;
  description: string;
};
type FormType = {
  type: boolean;
  footer: boolean;
};
export type IssuesMfo = 'карт' | 'кредитов' | 'займов';

export type QuestionFields = {
  title: string;
  description: string;
};

export type WhyWe = {
  subtitle: string;
  subtitleMobile: string;
};
type OneRequest = {
  title: string;
  text: string;
};
export interface LoanFields {
  advantages: AdvantagesCardDataType[];
  form?: FormType;
  feedback?: FeedbackDataType[];
  howitworks?: HowItWorksDataType[];
  atus?: AtUsDataTabType[];
  issuemfo?: IssuesMfo;
  question?: QuestionFields[];
  loanRequest?: string[];
  whywe?: WhyWe;
  onerequest?: OneRequest;
}

export type DomainProps<T> = { [key in DomainsName]: T };

interface Landing {
  landing1: DomainDataStoreProps;
  landing2: DomainDataStoreProps;
  landing3: DomainDataStoreProps;
  landing4: DomainDataStoreProps;
}

export type DomainDataStoreProps = {
  footer: DomainProps<FooterFields>;
  banner: DomainProps<BannerFields>;
  loan: DomainProps<LoanFields>;
};

export type footerDocumentType = {
  title: string;
  path: 'politics' | 'offer' | 'processing';
};

export type OwnerInfoTypeKeys = 'Samohina' | 'Vidyakin' | 'Mashtakov';

type OwnerInfoType = {
  owner: string;
  inn: string;
  licenseNumber: string;
  orderNumber: string;
  ogrn: string;
  address: {
    registrationAddress: string;
    factAddress: string;
  };
};
export const ownerData: { [key in OwnerInfoTypeKeys]: OwnerInfoType } = {
  Samohina: {
    owner: 'ИП Самохина Е.А.',
    inn: '744613115073',
    ogrn: '12525151',
    licenseNumber: '74-18-004739',
    orderNumber: '212 от 23.11.2018',
    address: {
      registrationAddress:
        '455038, Россия, Челябинская область, г. Магнитогорск, пр-кт Ленина, д. 122, кв. 33',
      factAddress:
        '455038, Россия, Челябинская область, г. Магнитогорск, пр-кт Ленина, д. 122, кв. 33',
    },
  },
  Vidyakin: {
    owner: 'ИП Видякин И.А.',
    inn: '6671262566',
    ogrn: '12525151',
    licenseNumber: '66-20-006575',
    orderNumber: '62 от 05.06.2020',
    address: {
      registrationAddress: '620026, Екатеринбург, Карла Маркса, д 12, кв 101',
      factAddress: '620026, Екатеринбург, Карла Маркса, д 12, кв 101',
    },
  },
  Mashtakov: {
    owner: 'ИП Маштаков Е.С.',
    inn: '',
    ogrn: '12525151',
    licenseNumber: '',
    orderNumber: '',
    address: {
      registrationAddress: '',
      factAddress: '',
    },
  },
};

export const footerDocumentsList: footerDocumentType[] = [
  { title: 'Партнеров', path: 'politics' },
  { title: 'обработку персональных данных', path: 'processing' },
  { title: 'оферту', path: 'offer' },
  {
    title: 'Политика относительно обработки персональных данных',
    path: 'processing',
  },
];

const DomainDataStore_1: DomainDataStoreProps = {
  banner: {
    cardonline: {
      logo: {
        imageMobile: banner_mobile_1,
        imageDesktop: banner_desktop_1,
      },

      subtitle: [
        'Лимит до 1 000 000 рублей',
        'Беспроцентный период до 365 дней',
        'Бесплатный выпуск и обслуживание',
      ],
    },
    zaimonline: {
      logo: {
        imageMobile: mobile_1_kn_mfo,
        imageDesktop: desktop_1_kn_mfo,
      },
      subtitle: ['Сумма до 30 000 рублей', 'Первый заём 0 %', 'Получение по паспорту'],
    },
    credonline: {
      logo: {
        imageMobile: mobile_1_kn_mfo,
        imageDesktop: desktop_1_kn_mfo,
      },
      subtitle: ['Лимит до 5 000 000 рублей', 'Ставка от 5,5%', 'Получение по паспорту'],
    },
  },
  loan: {
    cardonline: {
      advantages: [
        {
          id: 1,
          icon: credit_limit_1,
          text: 'Кредитный лимит\nдо 1 000 000₽',
        },
        {
          id: 2,
          icon: icon_365_1,
          text: 'Беспроцентный период\n до 365 дней',
        },
        {
          id: 3,
          icon: free_1,
          text: 'Бесплатный выпуск\nи обслуживание',
        },
        {
          id: 4,
          icon: cashback_1,
          text: 'Кешбек',
        },
        // {
        //   id: 5,
        //   icon: gift_1,
        //   text: 'Банк дарит 2 000 рублей',
        // },
      ],
      form: {
        type: true,
        footer: false,
      },
      feedback: [
        {
          icon: feedback_1_1,
          text: feedbackText.first.text_1,
          title: 'Михаил',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_1_2,
          text: feedbackText.first.text_2,
          title: 'Павел',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_1_3,
          text: feedbackText.first.text_3,
          title: 'Татьяна',
          subtitle: 'Преподаватель',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Заполните обязательное поле\nномера телефона',
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа Вам позвонят\nиз банка и озвучат условия',
        },
        {
          title: 'Получите карту',
          subtitle: 'Курьером или\nв отделении банка',
        },
      ],
      atus: [
        {
          id: 1,

          title: 'Высокий процент\n одобрений',
          subtitle: 'Наш процент\n одобрений — до 96%',
          description:
            'Меньше чем за час придет решение по вашей заявке.\nСистема автоматически подберет карту в соответствии с вашим запросом.',
        },
        {
          id: 2,

          title: 'Бесплатное\n обслуживание',
          subtitle: '0 рублей за\n обслуживание карты',
          description:
            'В зависимости от кредитной карты, Вы можете получить до\n одного года бесплатного обслуживания.',
        },
        {
          id: 3,

          title: 'Удобное\n использование',
          subtitle: 'Все, что вам нужно, это\n браузер',
          description:
            'Наш сервис адаптирован для удобного использования как на\n мобильном телефоне, так и компьютере.',
        },
        {
          id: 4,
          title: 'Оформление за\n 5 минут',
          subtitle: 'Весь процесс\n оформления карты\n занимает не более 5-ти минут',
          description:
            'Процесс оформления карты происходит очень быстро и занимает\n не более 5-ти минут',
        },
      ],
      issuemfo: 'карт',
      question: [
        {
          title: 'Как получить карту?',
          description:
            'Банк бесплатно доставит вашу карту курьером. Еще вы можете забрать карту в любом отделении банка.',
        },
        {
          title: 'Я потерял карту, что делать?',
          description:
            'Незамедлительно заблокировать карту - самостоятельно в мобильном приложении/интернет-банке или по телефону.',
        },
        {
          title: 'Как заблокировать карту?',
          description:
            'Заблокировать карту можно через интернет-банк/мобильное приложение или через контактый центр.',
        },
        {
          title: `От чего зависит процентная ставка?`,
          description:
            'Оформляя кредитную карту для физических лиц, важным моментом является выбор величины процентной ставки. От нее зависит сколько денег заемщику придется возвратить банку.\n' +
            '\n' +
            'При оформлении кредитной карты банком будет учитываться:\n' +
            '• наличие финансового дохода\n' +
            '• соотношение доходов и расходов\n' +
            'При большинстве положительных факторов банком может уменьшаться базовая ставка.',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники банка и озвучат\nодобренные условия.',
        },
      ],

      loanRequest: [],
    },
    credonline: {
      advantages: [
        {
          id: 1,
          icon: credit_limit_1,
          text: 'Лимит до 5 000 000 рублей',
        },
        {
          id: 2,
          icon: percent,
          text: 'Ставка от 5,5 %',
        },
        {
          id: 3,
          icon: passport,
          text: 'Получение по паспорту',
        },
        {
          id: 4,
          icon: icon_60_1,
          text: 'Срок кредита до 60 месяцев',
        },
        {
          id: 5,
          icon: mobile_1,
          text: 'Удобное погашение',
        },
      ],
      form: {
        type: true,
        footer: false,
      },
      feedback: [
        {
          icon: feedback_2_1,
          text: feedbackText.second.text_1,
          title: 'Сергей',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_2_2,
          text: feedbackText.second.text_2,
          title: 'Константин',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_2_3,
          text: feedbackText.second.text_3,
          title: 'Светлана',
          subtitle: 'Преподаватель',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Заполните обязательное поле\nномера телефона',
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа Вам позвонят\nиз банка и озвучат условия',
        },
        {
          title: 'Получите кредит',
          subtitle: 'Курьером или\nв отделении банка',
        },
      ],
      atus: [
        {
          id: 1,

          title: 'Высокий процент\n одобрений',
          subtitle: 'Наш процент\n одобрений 96%',
          description:
            'Меньше чем за час придет решение по вашей заявке.\nСистема автоматически подберет кредит в соответствии с вашим запросом.',
        },
        {
          id: 2,

          title: 'Низкая\nставка',
          subtitle: 'Ставка по кредитам\nот 5.5% годовых',
          description:
            'Ставки по кредитам, одни из самых низких на рынке. В\nзависимости от кредита, минимальная ставка 5,5% годовых.',
        },
        {
          id: 3,

          title: 'Удобное\n использование',
          subtitle: 'Все, что вам нужно, это\n браузер',
          description:
            'Наш сервис адаптирован для удобного использования как на\n мобильном телефоне, так и компьютере.',
        },
        {
          id: 4,

          title: 'Оформление за\n 5 минут',
          subtitle: 'Весь процесс\n оформления кредита\n занимает не более 5-ти минут',
          description:
            'Процесс оформления кредита происходит очень быстро и\nзанимает не более 5-ти минут',
        },
      ],
      issuemfo: 'кредитов',
      question: [
        {
          title: 'Почему могут отказать в кредите?',
          description:
            'Банк принимает решение об\nодобрении заявки на кредит,\n' +
            'используя специальную\nматематическую модель, которая\n' +
            'учитывает очень большие объемы\nданных. Именно поэтому иногда\n' +
            'сложно назвать конкретную причину\nотказа в кредите. Но если такая\n' +
            'возможность есть, банк укажет,\nпочему не одобрил кредит, в\nоповещении об отказе.',
        },
        {
          title: 'Как получить справку по кредиту?',
          description:
            'Справки по кредиту вы можете\nзаказать в мобильном приложении\nили отделении банка.',
        },
        {
          title: 'Могу ли я взять кредит, если у меня\nуже есть кредиты?',
          description:
            'Да, вы можете получить новый\nкредит, даже если у вас есть\nдействующие кредиты в других\nбанках.',
        },
        {
          title: `Как узнать задолженность по\nкредиту?`,
          description:
            'Узнать задолженность вы можете в\nмобильном приложении/интернет-\nбанке. Здесь вы можете увидеть всю\nинформацию по кредиту, в том числе\nо задолженности.',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся сотрудники банка и озвучат одобренные условия.',
        },
      ],

      loanRequest: [],
    },
    zaimonline: {
      advantages: [
        {
          id: 1,
          icon: credit_limit_1,
          text: 'Сумма до 30 000 рублей',
        },
        {
          id: 2,
          icon: percent,
          text: 'Первый заём 0 %',
        },
        {
          id: 3,
          icon: passport,
          text: 'Получение по паспорту',
        },
        {
          id: 4,
          icon: icon_36_1,
          text: 'Срок займа до 36 недель',
        },
        {
          id: 5,
          icon: mobile_1,
          text: 'Удобное погашение',
        },
      ],
      form: {
        type: true,
        footer: false,
      },
      feedback: [
        {
          icon: feedback_2_1,
          text: feedbackText.third.text_1,
          title: 'Сергей',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_2_2,
          text: feedbackText.third.text_2,
          title: 'Константин',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_2_3,
          text: feedbackText.third.text_3,
          title: 'Светлана',
          subtitle: 'Преподаватель',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Заполните обязательное поле\nномера телефона',
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа Вам позвонят\nиз МФО и озвучат условия',
        },
        {
          title: 'Получите заём',
          subtitle: 'Наличными или на карту\nвашего банка',
        },
      ],
      atus: [
        {
          id: 1,

          title: 'Высокий процент\n одобрений',
          subtitle: 'Наш процент\n одобрений 96%',
          description:
            'Меньше чем за час придет решение по вашей заявке.\nСистема автоматически подберет заём в соответствии с вашим запросом.',
        },
        {
          id: 2,

          title: 'Первый заём без\nпроцентов',
          subtitle: 'Ставка 0% на первый\nзаём',
          description: 'При первом займе мы предлагаем ставку 0%',
        },
        {
          id: 3,

          title: 'Удобное\n использование',
          subtitle: 'Все, что вам нужно, это\n браузер',
          description:
            'Наш сервис адаптирован для удобного использования как на\n мобильном телефоне, так и компьютере.',
        },
        {
          id: 4,

          title: 'Оформление за\n 5 минут',
          subtitle: 'Весь процесс\n оформления займа\n занимает не более 5-ти минут',
          description:
            'Процесс оформления карты происходит очень быстро и занимает\n не более 5-ти минут',
        },
      ],
      issuemfo: 'займов',
      question: [
        {
          title: 'Почему могут отказать в займе?',
          description:
            'МФО принимает решение об\nодобрении заявки на заём, ' +
            'используя\nспециальную математическую\nмодель, которая ' +
            'учитывает очень\nбольшие объемы данных. Именно\nпоэтому иногда ' +
            'сложно назвать\nконкретную причину отказа в займе.\nНо если такая ' +
            'возможность есть, МФО\nукажет,почему не одобрил заём, в\nоповещении об отказе.',
        },
        {
          title: 'Как получить заём?',
          description: 'На карту любого банка или\nэлектронный кошелек',
        },
        {
          title: 'Могу ли я взять кредит, если у меня\nуже есть кредиты?',
          description:
            'Да, вы можете получить заём, даже\nесли у вас есть действующие кредиты\nв других банках.',
        },
        {
          title: `Как узнать задолженность по\nкредиту?`,
          description: 'Через платежные терминалы или по\nреквизитам МФО',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники МФО и озвучат\nодобренные условия.',
        },
      ],
      loanRequest: [],
    },
  },
  footer: {
    cardonline: {
      domain: 'card-online.online',
      owner: 'Samohina',
    },
    credonline: {
      domain: 'credonline.ru',
      owner: 'Samohina',
    },
    zaimonline: {
      domain: 'zaim-online.online',
      owner: 'Samohina',
    },
  },
};
const DomainDataStore_2: DomainDataStoreProps = {
  banner: {
    cardonline: {
      title: 'Кредитная карта\n365 дней без процентов',
      logo: { imageMobile: banner_mobile_2, imageDesktop: banner_desktop_2 },
    },
    credonline: {
      title: 'Кредит c минимальной\nпроцентной ставкой',
      logo: {
        imageMobile: banner_mobile_2_kn_mfo,
        imageDesktop: banner_desktop_2_kn_mfo,
      },
    },
    zaimonline: {
      title: 'Заём c минимальной\nпроцентной ставкой',
      logo: {
        imageMobile: banner_mobile_2_kn_mfo,
        imageDesktop: banner_desktop_2_kn_mfo,
      },
    },
  },
  loan: {
    cardonline: {
      advantages: [
        {
          id: 1,
          icon: credit_limit_2,
          text: 'Кредитный лимит до 1 000 000₽',
        },
        {
          id: 2,
          icon: icon_365_2,
          text: 'Беспроцентный период 365 дней',
        },
        {
          id: 3,
          icon: free_2,
          text: 'Бесплатный выпуск и обслуживание',
        },
        {
          id: 4,
          icon: cashback_2,
          text: 'Кешбек',
        },
        {
          id: 5,
          icon: gift_2,
          text: 'Банк дарит 2 000 рублей',
        },
      ],
      feedback: [
        {
          icon: feedback_1_1,
          text: feedbackText.first.text_1,
          title: 'Михаил',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_1_2,
          text: feedbackText.first.text_2,
          title: 'Павел',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_1_3,
          text: feedbackText.first.text_3,
          title: 'Татьяна',
          subtitle: 'Преподаватель',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Заполните обязательное поле\nномер телефона',
          subtitleMobile: 'Заполните обязательное\nполе номер телефона',
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа Вам позвонят \nиз банка и озвучат условия',
        },
        {
          title: 'Получите карту',
          subtitle: 'Курьером или\nв отделении банка',
        },
      ],
      atus: [
        {
          id: 1,

          title: 'Высокий процент\n одобрений',
          subtitle: 'Наш процент\n одобрений 96%',
          description:
            'Меньше чем за час придет решение по вашей заявке.\nСистема автоматически подберет карту в соответствии с вашим запросом.',
        },
        {
          id: 2,

          title: 'Бесплатное\n обслуживание',
          subtitle: '0 рублей за\n обслуживание карты',
          description:
            'В зависимости от кредитной карты, Вы можете получить до\n одного года бесплатного обслуживания.',
        },
        {
          id: 3,

          title: 'Удобное\n использование',
          subtitle: 'Все, что вам нужно, это\n браузер',
          description:
            'Наш сервис адаптирован для удобного использования как на\n мобильном телефоне, так и компьютере.',
        },
        {
          id: 4,
          title: 'Оформление за\n 5 минут',
          subtitle: 'Весь процесс\n оформления карты\n занимает не более 5-ти минут',
          description:
            'Процесс оформления карты происходит очень быстро и занимает\n не более 5-ти минут',
        },
      ],
      question: [
        {
          title: 'Как получить карту?',
          description:
            'Банк бесплатно доставит вашу карту\nкурьером. Еще вы можете забрать\nкарту в любом отделении банка.',
        },
        {
          title: 'Я потерял карту, что делать?',
          description:
            'Незамедлительно заблокировать\nкарту - самостоятельно в мобильном\nприложении/интернет-банке или по\nтелефону.',
        },
        {
          title: 'Как заблокировать карту?',
          description:
            'Заблокировать карту можно через\nинтернет-банк/мобильное\nприложение или через контактый\nцентр.',
        },
        {
          title: 'От чего зависит процентная ставка?',
          description:
            'Оформляя кредитную карту для\nфизических лиц, важным моментом\n' +
            'является выбор величины процентной\nставки. От нее зависит сколько денег\n' +
            'заемщику придется возвратить банку.\n' +
            '\n' +
            'При оформлении кредитной карты\nбанком будет учитываться:\n' +
            'наличие финансового дохода\n' +
            'соотношение доходов и расходов\n' +
            'При большинстве положительных\nфакторов банком может уменьшаться\nбазовая ставка.',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники банка и озвучат\nодобренные условия.',
        },
      ],
    },
    credonline: {
      advantages: [
        {
          id: 1,
          icon: credit_limit_2,
          text: 'Лимит до 5 000 000 рублей',
        },
        {
          id: 2,
          icon: percent_2,
          text: 'Ставка от 5,5 %',
        },
        {
          id: 3,
          icon: passport_2,
          text: 'Получение по паспорту',
        },
        {
          id: 4,
          icon: icon_60_2,
          text: 'Срок займа до 60 месяцев',
        },
        {
          id: 5,
          icon: mobile_2,
          text: 'Удобное погашение',
        },
      ],
      feedback: [
        {
          icon: feedback_2_1,
          text: feedbackText.second.text_1,
          title: 'Сергей',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_2_2,
          text: feedbackText.second.text_2,
          title: 'Константин',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_2_3,
          text: feedbackText.second.text_3,
          title: 'Светлана',
          subtitle: 'Преподаватель',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Заполните обязательное поле\nномер телефона',
          subtitleMobile: 'Заполните обязательное\nполе номер телефона',
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа Вам позвонят \nиз банка и озвучат условия',
        },
        {
          title: 'Получите кредит',
          subtitle: 'Курьером или\nв отделении банка',
        },
      ],
      atus: [
        {
          id: 1,

          title: 'Высокий процент\n одобрений',
          subtitle: 'Наш процент\n одобрений 96%',
          description:
            'Меньше чем за час придет решение по вашей заявке.\nСистема автоматически подберет кредит в соответствии с вашим запросом.',
        },
        {
          id: 2,

          title: 'Низкая\nставка',
          subtitle: 'Ставка по кредитам\nот 5.5% годовых',
          description:
            'Ставки по кредитам, одни из самых низких на рынке. В\nзависимости от кредита, минимальная ставка 5,5% годовых.',
        },
        {
          id: 3,
          title: 'Удобное\n использование',
          subtitle: 'Все, что вам нужно, это\n браузер',
          description:
            'Наш сервис адаптирован для удобного использования как на\n мобильном телефоне, так и компьютере.',
        },
        {
          id: 4,

          title: 'Оформление за\n 5 минут',
          subtitle: 'Весь процесс\n оформления кредита\n занимает не более 5-ти минут',
          description:
            'Процесс оформления кредита происходит очень быстро и\nзанимает не более 5-ти минут',
        },
      ],
      question: [
        {
          title: 'Почему могут отказать в кредите?',
          description:
            'Банк принимает решение об\nодобрении заявки на кредит,\n' +
            'используя специальную\nматематическую модель, которая\n' +
            'учитывает очень большие объемы\nданных. Именно поэтому иногда\n' +
            'сложно назвать конкретную причину\nотказа в кредите. Но если такая\n' +
            'возможность есть, банк укажет,\nпочему не одобрил кредит, в\nоповещении об отказе.\n',
        },
        {
          title: 'Как получить справку по кредиту?',
          description:
            'Справки по кредиту вы можете\nзаказать в мобильном приложении\nили отделении банка.',
        },
        {
          title: 'Могу ли я взять кредит, если у меня уже есть кредиты?\n',
          description:
            'Да, вы можете получить новый\nкредит, даже если у вас есть\nдействующие кредиты в других\nбанках.',
        },
        {
          title: 'Как узнать задолженность по кредиту?\n',
          description:
            'Узнать задолженность вы можете в\nмобильном приложении/интернет\n' +
            '-банке. Здесь вы можете увидеть всю\nинформацию по кредиту, в том числе\n' +
            'о задолженности.',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники банка и озвучат\nодобренные условия.',
        },
      ],
    },
    zaimonline: {
      advantages: [
        {
          id: 1,
          icon: credit_limit_2,
          text: 'Сумма до 30 000 рублей',
        },
        {
          id: 2,
          icon: percent_2,
          text: 'Первый заём 0 %',
        },
        {
          id: 3,
          icon: passport_2,
          text: 'Получение по паспорту',
        },
        {
          id: 4,
          icon: icon_36_2,
          text: 'Срок займа до 36 недель',
        },
        {
          id: 5,
          icon: mobile_2,
          text: 'Удобное погашение',
        },
      ],
      feedback: [
        {
          icon: feedback_2_1,
          text: feedbackText.third.text_1,
          title: 'Сергей',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_2_2,
          text: feedbackText.third.text_2,
          title: 'Константин',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_2_3,
          text: feedbackText.third.text_3,
          title: 'Светлана',
          subtitle: 'Преподаватель',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Заполните обязательное поле\nномер телефона',
          subtitleMobile: 'Заполните обязательное\nполе номер телефона',
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа Вам позвонят \nиз МФО и озвучат условия',
        },
        {
          title: 'Получите заём',
          subtitle: 'Наличными или на карту\nвашего банка',
        },
      ],
      atus: [
        {
          id: 1,

          title: 'Высокий процент\n одобрений',
          subtitle: 'Наш процент\n одобрений 96%',
          description:
            'Меньше чем за час придет решение по вашей заявке.\nСистема автоматически подберет заём в соответствии с вашим запросом.',
        },
        {
          id: 2,

          title: 'Первый заём без\nпроцентов',
          subtitle: 'Ставка 0% на первый\nзаём',
          description: 'При первом займе мы предлагаем ставку 0%',
        },
        {
          id: 3,

          title: 'Удобное\n использование',
          subtitle: 'Все, что вам нужно, это\n браузер',
          description:
            'Наш сервис адаптирован для удобного использования как на\n мобильном телефоне, так и компьютере.',
        },
        {
          id: 4,

          title: 'Оформление за\n 5 минут',
          subtitle: 'Весь процесс\n оформления займа\n занимает не более 5-ти минут',
          description:
            'Процесс оформления карты происходит очень быстро и занимает\n не более 5-ти минут',
        },
      ],
      question: [
        {
          title: 'Почему могут отказать в займе?',
          description:
            'МФО принимает решение об\n' +
            'одобрении заявки на заём, используя\nспециальную математическую\n' +
            'модель, которая учитывает очень\nбольшие объемы данных. Именно\n' +
            'поэтому иногда сложно назвать\nконкретную причину отказа в займе.\n' +
            'Но если такая возможность есть, МФО\nукажет, почему не одобрил заём, в \n' +
            'оповещении об отказе.\n',
        },
        {
          title: 'Как получить заём?',
          description: 'На карту любого банка или\nэлектронный кошелек',
        },
        {
          title: 'Могу ли я взять заём, если у меня уже есть кредиты?\n',
          description:
            'Да, вы можете получить заём, даже\nесли у вас есть действующие кредиты\nв других банках.',
        },
        {
          title: 'Как оплатить заём?\n',
          description: 'Через платежные терминалы или по\nреквизитам МФО',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники МФО и озвучат\nодобренные условия.',
        },
      ],
    },
  },
  footer: {
    cardonline: {
      domain: 'card-online.online',
      owner: 'Samohina',
    },
    credonline: {
      domain: 'credonline.ru',
      owner: 'Samohina',
    },
    zaimonline: {
      domain: 'zaim-online.online',
      owner: 'Samohina',
    },
  },
};
const DomainDataStore_3: DomainDataStoreProps = {
  banner: {
    cardonline: {
      title: 'Получите карту 0% 365 дней',
      titleMobile: 'Получите карту\n0% 365 дней',
      subtitle: [
        'Лимит до 1 000 000 рублей',
        'Бесплатный выпуск и обслуживание',
        'Автоматическое одобрение первой заявки\nпо номеру телефона',
      ],
      logo: { imageDesktop: banner_desktop_3 },
      subtitleMobile: [
        'Лимит до 1 000 000 рублей',
        'Бесплатный выпуск\nи обслуживание',
        'Автоматическое\nодобрение первой заявки\nпо номеру телефона',
      ],
    },
    credonline: {
      title: 'Получите кредит от 5,5%',
      titleMobile: 'Получите кредит\nот 5,5%',
      subtitle: [
        'Лимит до 5 000 000 рублей',
        'Получение по паспорту',
        'Автоматическое одобрение первой заявки \nпо номеру телефона',
      ],
      logo: { imageDesktop: banner_desktop_3_kn },
      subtitleMobile: [
        'Лимит до 5 000 000 рублей',
        'Получение по\nпаспорту',
        'Автоматическое\nодобрение первой заявки\nпо номеру телефона',
      ],
    },
    zaimonline: {
      title: 'Получите заём от 0%',
      titleMobile: 'Получите заём\nот 0%',
      subtitle: [
        'Сумма до 30 000 рублей',
        'Получение по паспорту',
        'Автоматическое одобрение первой заявки \nпо номеру телефона',
      ],
      logo: {
        imageDesktop: banner_desktop_3,
      },
      subtitleMobile: [
        'Сумма до 30 000 рублей',
        'Получение по\nпаспорту',
        'Автоматическое\nодобрение первой заявки\nпо номеру телефона',
      ],
    },
  },
  loan: {
    cardonline: {
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Быстро и удобно',
          text: [
            'Работаем круглосуточно',
            'Для получения нужен только\nпаспорт гражданина РФ',
          ],
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа',
          text: ['Ожидайте звонка от банка с решением', 'Одобрение заявки 96%'],
        },
        {
          title: 'Получите карту',
          subtitle: 'Быстрое получение',
          text: ['Курьером или\nв отделении банка'],
        },
      ],
      advantages: [
        {
          id: 1,
          icon: credit_limit_3,
          text: 'Кредитный лимит до 1 000 000₽',
        },
        {
          id: 2,
          icon: icon_365_3,
          text: 'Беспроцентный период 365 дней',
        },
        {
          id: 3,
          icon: free_3,
          text: 'Бесплатный выпуск и обслуживание',
        },
        {
          id: 4,
          icon: cashback_3,
          text: 'Кешбек',
        },
        {
          id: 5,
          icon: gift_3,
          text: 'Банк дарит 2 000 рублей',
        },
      ],
      feedback: [
        {
          icon: feedback_1_1,
          text: feedbackText.first.text_1,
          title: 'Михаил',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_1_2,
          text: feedbackText.first.text_2,
          title: 'Павел',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_1_3,
          text: feedbackText.first.text_3,
          title: 'Татьяна',
          subtitle: 'Преподаватель',
        },
      ],
      question: [
        {
          title: 'Как получить карту?',
          description:
            'Банк бесплатно доставит вашу карту курьером. Еще вы можете забрать карту в любом отделении банка.',
        },
        {
          title: 'Я потерял карту, что делать?',
          description:
            'Незамедлительно заблокировать карту - самостоятельно в мобильном приложении/интернет-банке или по телефону.',
        },
        {
          title: 'Как заблокировать карту?',
          description:
            'Заблокировать карту можно через интернет-банк/мобильное приложение или через контактый центр.',
        },
        {
          title: `От чего зависит процентная ставка?`,
          description:
            'Оформляя кредитную карту для физических лиц, важным моментом является выбор величины процентной ставки. От нее зависит сколько денег заемщику придется возвратить банку.\n' +
            '\n' +
            'При оформлении кредитной карты банком будет учитываться:\n' +
            '• наличие финансового дохода\n' +
            '• соотношение доходов и расходов\n' +
            'При большинстве положительных факторов банком может уменьшаться базовая ставка.',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники банка и озвучат\nодобренные условия.',
        },
      ],
    },
    credonline: {
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Быстро и удобно',
          text: [
            'Работаем круглосуточно',
            'Для получения нужен только\nпаспорт гражданина РФ',
          ],
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа',
          text: ['Ожидайте звонка от банка с решением', 'Одобрение заявки 96%'],
        },
        {
          title: 'Получите кредит',
          subtitle: 'Быстрое получение',
          text: ['Курьером или\nв отделении банка'],
        },
      ],
      advantages: [
        {
          id: 1,
          icon: credit_limit_3,
          text: 'Лимит до 5 000 000 рублей',
        },
        {
          id: 2,
          icon: percent_3,
          text: 'Ставка от 5,5 %',
        },
        {
          id: 3,
          icon: passport_3,
          text: 'Получение по паспорту',
        },
        {
          id: 4,
          icon: icon_60_3,
          text: 'Срок займа до 60 месяцев',
        },
        {
          id: 5,
          icon: mobile_3,
          text: 'Удобное погашение',
        },
      ],
      feedback: [
        {
          icon: feedback_2_1,
          text: feedbackText.second.text_1,
          title: 'Сергей',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_2_2,
          text: feedbackText.second.text_2,
          title: 'Константин',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_2_3,
          text: feedbackText.second.text_3,
          title: 'Светлана',
          subtitle: 'Преподаватель',
        },
      ],
      question: [
        {
          title: 'Почему могут отказать в кредите?',
          description:
            'Банк принимает решение об\nодобрении заявки на кредит,\n' +
            'используя специальную\nматематическую модель, которая\n' +
            'учитывает очень большие объемы\nданных. Именно поэтому иногда\n' +
            'сложно назвать конкретную причину\nотказа в кредите. Но если такая\n' +
            'возможность есть, банк укажет,\nпочему не одобрил кредит, в\nоповещении об отказе.',
        },
        {
          title: 'Как получить справку по кредиту?',
          description:
            'Справки по кредиту вы можете\nзаказать в мобильном приложении\nили отделении банка.',
        },
        {
          title: 'Могу ли я взять кредит, если у меня\nуже есть кредиты?',
          description:
            'Да, вы можете получить новый\nкредит, даже если у вас есть\nдействующие кредиты в других\nбанках.',
        },
        {
          title: `Как узнать задолженность по\nкредиту?`,
          description:
            'Узнать задолженность вы можете в\nмобильном приложении/интернет-\nбанке. Здесь вы можете увидеть всю\nинформацию по кредиту, в том числе\nо задолженности.',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся сотрудники банка и озвучат одобренные условия.',
        },
      ],
    },
    zaimonline: {
      howitworks: [
        {
          title: 'Укажите номер',
          subtitle: 'Быстро и удобно',
          text: ['Быстро и удобно', 'Для получения нужен только\nпаспорт гражданина РФ'],
        },
        {
          title: 'Дождитесь звонка',
          subtitle: 'В течение 1 часа',
          text: ['Ожидайте звонка от МФО с решением', 'Одобрение заявки 96%'],
        },
        {
          title: 'Получите карту',
          subtitle: 'Быстрое получение',
          text: ['Наличными или на карту\nвашего банка'],
        },
      ],
      advantages: [
        {
          id: 1,
          icon: credit_limit_3,
          text: 'Сумма до 30 000 рублей',
        },
        {
          id: 2,
          icon: percent_3,
          text: 'Первый заём 0 %',
        },
        {
          id: 3,
          icon: passport_3,
          text: 'Получение по паспорту',
        },
        {
          id: 4,
          icon: icon_36_3,
          text: 'Срок займа до 36 недель',
        },
        {
          id: 5,
          icon: mobile_3,
          text: 'Удобное погашение',
        },
      ],
      feedback: [
        {
          icon: feedback_2_1,
          text: feedbackText.third.text_1,
          title: 'Сергей',
          subtitle: 'Консультант',
        },
        {
          icon: feedback_2_2,
          text: feedbackText.third.text_2,
          title: 'Константин',
          subtitle: 'Менеджер',
        },
        {
          icon: feedback_2_3,
          text: feedbackText.third.text_3,
          title: 'Светлана',
          subtitle: 'Преподаватель',
        },
      ],
      question: [
        {
          title: 'Почему могут отказать в займе?',
          description:
            'МФО принимает решение об\nодобрении заявки на заём, ' +
            'используя\nспециальную математическую\nмодель, которая ' +
            'учитывает очень\nбольшие объемы данных. Именно\nпоэтому иногда ' +
            'сложно назвать\nконкретную причину отказа в займе.\nНо если такая ' +
            'возможность есть, МФО\nукажет,почему не одобрил заём, в\nоповещении об отказе.',
        },
        {
          title: 'Как получить заём?',
          description: 'На карту любого банка или\nэлектронный кошелек',
        },
        {
          title: 'Могу ли я взять кредит, если у меня\nуже есть кредиты?',
          description:
            'Да, вы можете получить заём, даже\nесли у вас есть действующие кредиты\nв других банках.',
        },
        {
          title: `Как узнать задолженность по\nкредиту?`,
          description: 'Через платежные терминалы или по\nреквизитам МФО',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники МФО и озвучат\nодобренные условия.',
        },
      ],
    },
  },
  footer: {
    cardonline: { domain: 'card-online.online', owner: 'Samohina' },
    credonline: { domain: 'credonline.ru', owner: 'Samohina' },
    zaimonline: { domain: 'zaim-online.online', owner: 'Samohina' },
  },
};
const DomainDataStore_4: DomainDataStoreProps = {
  banner: {
    cardonline: {
      title: ' Кредитная карта — \n не выходя из дома',
      subtitle: [
        'Лимит до 1 000 000 рублей',
        'Беспроцентный период 365 дней',
        'Автоматическое одобрение первой\nзаявки по номеру телефона',
      ],
      subtitleMobile: [
        'Лимит до 1 000 000 рублей',
        'Беспроцентный период\n365 дней',
        'Автоматическое одобрение\nпервой заявки по номеру\nтелефона',
      ],
      logo: {},
    },
    credonline: {
      title: 'Выгодный кредит —\nне выходя из дома',
      subtitle: [
        'Лимит до 5 000 000 рублей',
        'Получение по паспорту',
        'Автоматическое одобрение первой\nзаявки по номеру телефона',
      ],
      subtitleMobile: [
        'Лимит до 5 000 000 рублей',
        'Получение по паспорту',
        'Автоматическое одобрение\nпервой заявки по номеру\nтелефона',
      ],
      logo: {},
    },
    zaimonline: {
      title: 'Выгодный заём — \nне выходя из дома',
      subtitle: [
        'Сумма до 30 000 рублей',
        'Получение по паспорту',
        'Автоматическое одобрение первой\nзаявки по номеру телефона',
      ],
      subtitleMobile: [
        'Сумма до 30 000 рублей',
        'Получение по паспорту',
        'Автоматическое одобрение\nпервой заявки по номеру\nтелефона',
      ],
      logo: {},
    },
  },
  loan: {
    cardonline: {
      whywe: {
        subtitle:
          'Мы отправим заявки в банки в нужном порядке\n' +
          'и с необходимыми интервалами,\n' +
          'чтобы ваши шансы получить карту были максимальны\n' +
          'Сервис полностью бесплатный',
        subtitleMobile:
          'Мы отправим заявки в банки\nв нужном порядке\n' +
          'и с необходимыми\nинтервалами,\n' +
          'чтобы ваши шансы получить\nкарту были максимальны\n' +
          'Сервис полностью бесплатный',
      },
      advantages: [],
      feedback: [
        {
          text: 'Оформлял кредитную карту, одобрили быстро и бесплатно привезли в назначенное время.Советую всем друзьям и родственникам.',
          author: 'Михаил',
          title: '',
          subtitle: '',
          icon: '',
        },
        {
          text: 'Давно пользуюсь кредитками. Доволен поддержкой и возможностями. Это самая удобная кредитка, из тех которые у меня были.',
          author: 'Павел',
          title: '',
          subtitle: '',
          icon: '',
        },
        {
          text: 'Я кредиткой уже несколько лет пользуюсь. Стараюсь за льготный период не выходить, проценты почти не плачу. По мне, кредитка лучше, чем обычный кредит.',
          author: 'Татьяна',
          title: '',
          subtitle: '',
          icon: '',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          titleMobile: 'Укажите номер',
          subtitle: '01',
        },
        {
          title: 'Дождитесь звонка',
          titleMobile: 'Дождитесь\nзвонка',
          subtitle: '02',
        },
        {
          title: 'Получите карту',
          titleMobile: 'Получите\nкарту',
          subtitle: '03',
        },
      ],
      onerequest: {
        title: 'Одна заявка - во все банки',
        text: 'Ваша заявка будет отправляться в банки с наибольшей вероятностью одобрения в данный момент времени с соблюдением необходимых пауз между отправками. При предварительном одобрении банки сами связываются с вами, и вам необходимо лишь подтвердить свое согласие и забрать карту.',
      },
      question: [
        {
          title: 'Как получить карту?',
          description:
            'Банк бесплатно доставит вашу карту курьером. Еще вы можете забрать карту в любом отделении банка.',
        },
        {
          title: 'Я потерял карту, что делать?',
          description:
            'Незамедлительно заблокировать карту - самостоятельно в мобильном приложении/интернет-банке или по телефону.',
        },
        {
          title: 'Как заблокировать карту?',
          description:
            'Заблокировать карту можно через интернет-банк/мобильное приложение или через контактый центр.',
        },
        {
          title: `От чего зависит процентная ставка?`,
          description:
            'Оформляя кредитную карту для физических лиц, важным моментом является выбор величины процентной ставки. От нее зависит сколько денег заемщику придется возвратить банку.\n' +
            '\n' +
            'При оформлении кредитной карты банком будет учитываться:\n' +
            '• наличие финансового дохода\n' +
            '• соотношение доходов и расходов\n' +
            'При большинстве положительных факторов банком может уменьшаться базовая ставка.',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники банка и озвучат\nодобренные условия.',
        },
      ],
    },
    credonline: {
      whywe: {
        subtitle:
          'Мы отправим заявки в банки в нужном порядке\n' +
          'и с необходимыми интервалами,\n' +
          'чтобы ваши шансы получить кредит были максимальны\n' +
          'Сервис полностью бесплатный',
        subtitleMobile:
          'Мы отправим заявки в банки в\nнужном порядке\n' +
          'и с необходимыми\nинтервалами,\n' +
          'чтобы ваши шансы получить\nкредит были максимальны\n' +
          'Сервис полностью бесплатный',
      },
      advantages: [],
      feedback: [
        {
          text: 'Оформлял кредит, одобрили быстро и деньги получил в назначенное время.Советую всем друзьям и родственникам.',
          author: 'Сергей',
          title: '',
          subtitle: '',
          icon: '',
        },
        {
          text: 'Давно пользуюсь кредитами. Доволен поддержкой и возможностями. Это самый удобный сервис, из тех которыми я пользовался.',
          author: 'Константин',
          title: '',
          subtitle: '',
          icon: '',
        },
        {
          text: 'Недавно получала кредит. Очень довольна обслуживанием! Хочу выразить благодарность сотрудникам, делают всё очень грамотно и быстро.',
          author: 'Светлана',
          title: '',
          subtitle: '',
          icon: '',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          titleMobile: 'Укажите номер',
          subtitle: '01',
        },
        {
          title: 'Дождитесь звонка',
          titleMobile: 'Дождитесь\nзвонка',
          subtitle: '02',
        },
        {
          title: 'Получите кредит',
          titleMobile: 'Получите\nкредит',
          subtitle: '03',
        },
      ],
      onerequest: {
        title: 'Одна заявка - во все банки',
        text: 'Ваша заявка будет отправляться в банки с наибольшей вероятностью одобрения в данный момент времени с соблюдением необходимых пауз между отправками. При предварительном одобрении банки сами связываются с вами, и вам необходимо лишь подтвердить свое согласие и оформить кредит.',
      },
      question: [
        {
          title: 'Почему могут отказать в кредите?',
          description:
            'Банк принимает решение об\nодобрении заявки на кредит,\n' +
            'используя специальную\nматематическую модель, которая\n' +
            'учитывает очень большие объемы\nданных. Именно поэтому иногда\n' +
            'сложно назвать конкретную причину\nотказа в кредите. Но если такая\n' +
            'возможность есть, банк укажет,\nпочему не одобрил кредит, в\nоповещении об отказе.',
        },
        {
          title: 'Как получить справку по кредиту?',
          description:
            'Справки по кредиту вы можете\nзаказать в мобильном приложении\nили отделении банка.',
        },
        {
          title: 'Могу ли я взять кредит, если у меня\nуже есть кредиты?',
          description:
            'Да, вы можете получить новый\nкредит, даже если у вас есть\nдействующие кредиты в других\nбанках.',
        },
        {
          title: `Как узнать задолженность по\nкредиту?`,
          description:
            'Узнать задолженность вы можете в\nмобильном приложении/интернет-\nбанке. Здесь вы можете увидеть всю\nинформацию по кредиту, в том числе\nо задолженности.',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся сотрудники банка и озвучат одобренные условия.',
        },
      ],
    },
    zaimonline: {
      whywe: {
        subtitle:
          'Мы отправим заявки в МФО в нужном порядке\n' +
          'и с необходимыми интервалами,\n' +
          'чтобы ваши шансы получить заём были максимальны\n' +
          'Сервис полностью бесплатный',
        subtitleMobile:
          'Мы отправим заявки в МФО в\nнужном порядке\n' +
          'и с необходимыми интервалами,\n' +
          'чтобы ваши шансы получить\nзаём были максимальны\n' +
          'Сервис полностью бесплатный',
      },
      advantages: [],
      feedback: [
        {
          text: 'Оформлял заём, одобрили быстро и деньги получил в назначенное время.Советую всем друзьям и родственникам.',
          author: 'Сергей',
          title: '',
          subtitle: '',
          icon: '',
        },
        {
          text: 'Давно пользуюсь услугами МФО. Доволен поддержкой и возможностями. Это самый удобный сервис, из тех которыми я пользовался.',
          author: 'Константин',
          title: '',
          subtitle: '',
          icon: '',
        },
        {
          text: 'Недавно получала заём. Очень довольна обслуживанием! Хочу выразить благодарность сотрудникам, делают всё очень грамотно и быстро.',
          author: 'Светлана',
          title: '',
          subtitle: '',
          icon: '',
        },
      ],
      howitworks: [
        {
          title: 'Укажите номер',
          titleMobile: 'Укажите номер',
          subtitle: '01',
        },
        {
          title: 'Дождитесь звонка',
          titleMobile: 'Дождитесь\nзвонка',
          subtitle: '02',
        },
        {
          title: 'Получите заём',
          titleMobile: 'Получите\nзаём',
          subtitle: '03',
        },
      ],
      onerequest: {
        title: 'Одна заявка - во все МФО',
        text: 'Ваша заявка будет отправляться в МФО с наибольшей вероятностью одобрения в данный момент времени с соблюдением необходимых пауз между отправками. При предварительном одобрении банки сами связываются с вами, и вам необходимо лишь подтвердить свое согласие и оформить заём.',
      },
      question: [
        {
          title: 'Почему могут отказать в займе?',
          description:
            'МФО принимает решение об\nодобрении заявки на заём, ' +
            'используя\nспециальную математическую\nмодель, которая ' +
            'учитывает очень\nбольшие объемы данных. Именно\nпоэтому иногда ' +
            'сложно назвать\nконкретную причину отказа в займе.\nНо если такая ' +
            'возможность есть, МФО\nукажет,почему не одобрил заём, в\nоповещении об отказе.',
        },
        {
          title: 'Как получить заём?',
          description: 'На карту любого банка или\nэлектронный кошелек',
        },
        {
          title: 'Могу ли я взять кредит, если у меня\nуже есть кредиты?',
          description:
            'Да, вы можете получить заём, даже\nесли у вас есть действующие кредиты\nв других банках.',
        },
        {
          title: `Как узнать задолженность по\nкредиту?`,
          description: 'Через платежные терминалы или по\nреквизитам МФО',
        },
        {
          title: 'Сколько обрабатывается заявка?',
          description:
            'В течение 1 часа с вами свяжутся\nсотрудники МФО и озвучат\nодобренные условия.',
        },
      ],
    },
  },
  footer: {
    cardonline: { domain: 'card-online.online', owner: 'Samohina' },
    credonline: { domain: 'credonline.ru', owner: 'Samohina' },
    zaimonline: { domain: 'zaim-online.online', owner: 'Samohina' },
  },
};

export const LandingStore: Landing = {
  landing1: DomainDataStore_1,
  landing2: DomainDataStore_2,
  landing3: DomainDataStore_3,
  landing4: DomainDataStore_4,
};
