import { Nullable } from '../../types';

type documentType = 'processing' | 'offer' | 'politics';

type DocumentStateType = {
  pdfFile: Nullable<documentType>;
  opened: boolean;
};

const initialDocumentState: DocumentStateType = {
  pdfFile: null,
  opened: false,
};

export { DocumentStateType, documentType, initialDocumentState };
