import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreditFormType, CreditParameterInfo } from 'store/creditFormSlice/types';

const creditFormInitialState: CreditFormType = {
  credit_parameter_info: {
    name: '',
    surname: '',
    patronymic: '',
    phone_number: '',
  },
};

const creditFormSlice = createSlice({
  name: 'credit-form',
  initialState: creditFormInitialState,
  reducers: {
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.credit_parameter_info.phone_number = action.payload;
    },
    setCreditInfo: (state, action: PayloadAction<CreditParameterInfo>) => {
      state.credit_parameter_info = action.payload;
    },
  },
});

export const creditReducer = creditFormSlice.reducer;
export const { setCreditInfo, setPhoneNumber } = creditFormSlice.actions;
