import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import axios from 'axios';
import clsx from 'clsx';
import { Formik } from 'formik';

import { FormApi } from '../../../../api/form-service/FormApi';
import { MediaContext } from '../../../../context/MediaProvider';
import { ModalContext, ModalContextType } from '../../../../context/ModalProvider';
import { useHistoryWithUTM } from '../../../../hook/useHistoryWithUTM';
import DocumentModel from '../../../../models/DocumentModel/DocumentModel';

import { useAppDispatch } from 'hook/useAppDispatch';
import { useAppSelector } from 'hook/useAppSelector';
import { CreditInfoSchema } from 'pages/Form/components/CreditInfo/schema/CreditInfoSchema';
import s from 'pages/Form/components/CreditInfo/style/CreditInfo.module.scss';
import { Button } from 'shared/Button';
import { InputField } from 'shared/InputField';
import { Paper } from 'shared/Paper/Paper';
import { CreditParameterInfo, StartCreditParameters } from 'store/creditFormSlice/types';
import com from 'styles/common.module.scss';
import { checkFocusError, isGeoInfoFilled, notify, resetMask } from 'utils';

type CreditInfoPropsType = {
  isFormType: boolean;
};

const PHONE_LENGTH = 11;

export const CreditInfoForm: React.FC<CreditInfoPropsType> = React.memo(
  ({ isFormType }) => {
    const [focus, setFocus] = useState(false);
    const { initPhone, initNumber, setOpenAuthModal } = useContext(
      ModalContext,
    ) as ModalContextType;
    const creditInfo = useAppSelector(state => state.credit.credit_parameter_info);
    const geoInfo = useAppSelector(state => state.app.geo);
    const dispatch = useAppDispatch();
    const history = useHistoryWithUTM();
    const focusRef = useRef<HTMLFormElement>(null);
    const tablet = useContext(MediaContext);
    const { openPdf } = DocumentModel();

    const openOfferHandler = useCallback(() => {
      openPdf('offer');
    }, []);

    const openProcessingHandler = useCallback(() => {
      openPdf('processing');
    }, []);

    const initialValues: CreditParameterInfo = {
      name: creditInfo.name || '',
      surname: creditInfo.surname || '',
      patronymic: creditInfo.patronymic || '',
      phone_number: initPhone || creditInfo.phone_number || '',
    };

    useEffect(() => {
      checkFocusError(focusRef, setFocus, focus).then();
    }, [focus, dispatch]);

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={CreditInfoSchema}
        initialTouched={{
          name: !!creditInfo.name,
          surname: !!creditInfo.surname,
          patronymic: !!creditInfo.patronymic,
          phone_number: !!creditInfo.phone_number,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const normalizedData: CreditParameterInfo = {
            phone_number: resetMask(values.phone_number),
          };

          if (values.name) {
            Object.assign(normalizedData, { name: values.name });
          }
          if (values.surname) {
            Object.assign(normalizedData, { surname: values.surname });
          }
          if (values.patronymic) {
            Object.assign(normalizedData, { patronymic: values.patronymic });
          }

          setSubmitting(false);
          try {
            await FormApi.updateUser(normalizedData);
            setOpenAuthModal(false);
            history.push('/second');
          } catch (err) {
            if (axios.isAxiosError(err)) {
              notify('Не удалось отправить заявку');
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            ref={focusRef}
            className={s.credit}
          >
            <div className={isFormType ? com.container : tablet ? com.container : ''}>
              {!isFormType && (
                <h4 className={clsx(com.title__h4, s.credit__title)}>
                  Заявка на кредитную карту
                </h4>
              )}
              <Paper>
                {isFormType && <h4 className={com.title__h4}>Основная информация</h4>}
                <div className={isFormType ? s.credit__wrapper : s.credit__wrapperModal}>
                  <div className={isFormType ? s.credit__row : s.credit__rowModal}>
                    <InputField
                      label="Мобильный телефон"
                      required
                      placeholder="+7-(___)-___-__-__"
                      mask="+7-(999)-999-99-99"
                      autoComplete="off"
                      inputMode="tel"
                      role="presentation"
                      name="phone_number"
                      value={values.phone_number}
                      readOnly={false}
                      onChange={async e => {
                        handleChange(e);
                        const clearPhone = resetMask(e.currentTarget.value);
                        if (
                          clearPhone.length === PHONE_LENGTH &&
                          resetMask(values.phone_number).length !== PHONE_LENGTH
                        ) {
                          const normalizedData: StartCreditParameters = {
                            phone_number: clearPhone,
                            url: window.location.href,
                          };
                          if (isGeoInfoFilled(geoInfo)) {
                            Object.assign(normalizedData, { geo: geoInfo });
                          }
                          await FormApi.createUser(normalizedData);
                        }
                      }}
                      touched={resetMask(values.phone_number).length === PHONE_LENGTH}
                      error={touched.phone_number ? errors.phone_number : ''}
                    />
                  </div>

                  <div className={isFormType ? s.credit__row : s.credit__rowModal}>
                    <InputField
                      disabled={resetMask(values.phone_number).length !== PHONE_LENGTH}
                      label="Фамилия"
                      placeholder="Например: Иванов"
                      name="surname"
                      autoComplete="off"
                      role="presentation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.surname}
                      touched={touched.surname}
                      error={touched.surname ? errors.surname : ''}
                    />
                    <InputField
                      disabled={resetMask(values.phone_number).length !== PHONE_LENGTH}
                      label="Имя"
                      placeholder="Например: Иван"
                      name="name"
                      autoComplete="off"
                      role="presentation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={touched.name ? errors.name : ''}
                      touched={touched.name}
                    />
                    <InputField
                      disabled={resetMask(values.phone_number).length !== PHONE_LENGTH}
                      label="Отчество"
                      placeholder="Например: Иванович"
                      name="patronymic"
                      autoComplete="off"
                      role="presentation"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.patronymic}
                      touched={touched.patronymic}
                      error={touched.patronymic ? errors.patronymic : ''}
                    />
                  </div>
                </div>
                {!isFormType && (
                  <Button
                    containerClassName={s.credit__btnGroup}
                    className={clsx(s.credit__btnModal, s[`credit__btn_${initNumber}`])}
                    disabled={isSubmitting}
                    onClick={() => {
                      setFocus(true);
                    }}
                    type="submit"
                    dataCy="form_submit_button"
                  >
                    Оформить карту
                  </Button>
                )}
                <div className={clsx(s.link, s.link__modal)}>
                  {/* <span>Нажимая кнопку &quot;Оформить карту&quot;, Вы соглашаетесь </span> */}
                  {/* <a href="https://sobank.online/static/docs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8..pdf"> */}
                  {/*  c правилами обработки информации */}
                  {/* </a> */}
                  {/* Я соглашаюсь на обработку моих персональных данных и с */}
                  {/* условиями оферты */}
                  <span className="agreement-text">
                    Я даю{' '}
                    <button
                      onClick={openProcessingHandler}
                      type="button"
                      className={s.linkButton}
                    >
                      согласие
                    </button>{' '}
                    на обработку моих персональных данных, получение информационных и
                    рекламных сообщений, а также соглашаюсь с условиями{' '}
                    <button
                      onClick={openOfferHandler}
                      type="button"
                      className={s.linkButton}
                    >
                      оферты
                    </button>
                  </span>
                </div>

                {isFormType && (
                  <Button
                    containerClassName={`${s.credit__btnGroup} ${s.mobile}`}
                    className={clsx(s.credit__btn, s[`credit__btn_${initNumber}`])}
                    disabled={isSubmitting}
                    onClick={() => setFocus(true)}
                    type="submit"
                    dataCy="form_submit_button"
                  >
                    Оформить карту
                  </Button>
                )}
              </Paper>
              {isFormType && (
                <Button
                  containerClassName={`${s.credit__btnGroup} ${s.desktop}`}
                  className={clsx(s.credit__btn, s[`credit__btn_${initNumber}`])}
                  disabled={isSubmitting}
                  onClick={() => {
                    setFocus(true);
                  }}
                  type="submit"
                  dataCy="form_submit_button"
                >
                  Оформить карту
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    );
  },
);
