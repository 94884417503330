import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

export const useHistoryWithUTM = () => {
  const history = useHistory();
  const [search, setSearch] = useState(history.location.search);
  useEffect(() => {
    setSearch(history.location.search);
  }, [history.location.search]);

  return {
    push: (pathname: string) => {
      history.push({ pathname, search });
    },
    checkProtocol(protocol: string) {
      return window.location.protocol === protocol;
    },
    toHttps() {
      if (this.checkProtocol('http:') && !window.location.origin.includes('localhost')) {
        window.location.href = window.location.href.replace('http:', 'https:');
      }
    },
    location: history.location,
    returnToBack: () => history.goBack(),
  };
};
